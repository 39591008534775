import { LoadingButton } from "@mui/lab";
import { Box, Link, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Password from "src/components/Password";
import ChoosePlan from "src/components/choose-plan";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import Translate from "src/components/translate";
import GoogleOAuthButton from "src/pages/auth/components/google-login";
import { PATH_AUTH } from "src/routes/paths";
import AuthHelper from "./components/authHelper";
import EnterOtp from "./components/enter-otp";
import VerifyOtp from "./components/verify-otp";
import ErrorBanner from "./components/error-banner";
import useLogin from "./hooks/use-login";
import { tr } from "date-fns/locale";
import axiosInstance from "src/utils/axios";
import { useState } from "react";

const LoginForm = () => {
  const { onSubmit, methods } = useLogin();
  const {
    watch,
    setError,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const data = watch();
  // const [open, setOpen] = useState(false);
  const handleLogin = async (inputData) => {
    const formData = new FormData();
    Object.entries(inputData).forEach(([k, v]) => formData.append(k, v));
    try {
      const { data: responseData, status } = await axiosInstance.post(
        "api/verify-user",
        formData
      );
      if (status === 200) {
        const { is_admin, is_sub_admin } = responseData;
        setValue("is_admin", is_admin);
        setValue("is_sub_admin", is_sub_admin);
        if (is_admin || is_sub_admin) {
          handleSubmit(onSubmit)();
          return;
        }

        setError("afterSubmit", {
          message: "You do not have the necessary permissions to access this system.",
        });
        // setOpen(true);
      }
    } catch (err) {
      setError("afterSubmit", {
        message: err.message,
      });

      Object.entries(err.errors).forEach(([k, v]) =>
        setError(k, { message: v[0] })
      );
    }
  };
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mb={2}>
          <AuthHelper />
          <ErrorBanner />
        </Stack>

        <Box>
          <Stack spacing={2}>
            <RHFTextField name="username" label="register.username" />
            <Password name="password" label="register.password" />
            <ChoosePlan />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <RHFCheckbox name="remember" label="register.remember" />
            <Link
              component={RouterLink}
              variant="subtitle2"
              sx={{
                color: "GrayText",
              }}
              to={PATH_AUTH.resetPassword}
            >
              <Translate>register.forgot</Translate>
            </Link>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            // onClick={methods.handleSubmit()}
            name="login"
          >
            <Translate>register.login</Translate>
          </LoadingButton>

          {/* <GoogleOAuthButton
                        plan={methods.getValues("plan")}
                        buttonLabel="register.google_login"
                    /> */}
        </Box>
        {/* <VerifyOtp open={open} onSubmit={onSubmit} /> */}
      </FormProvider>
      {/* <EnterOtp data={data} /> */}
    </>
  );
};

export default LoginForm;
