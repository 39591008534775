import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Lucky = Loadable(lazy(() => import("src/pages/admin/lucky/list")));
const History = Loadable(lazy(() => import("src/pages/admin/lucky/history")));

const lucky = [
  {
    path: "lucky",
    children: [
      { element: <Navigate to="lucky_pool" />, index: true },
      { path: "lucky_pool", element: <Lucky /> },
      { path: "history", element: <History /> },
    ],
  },
];

export default lucky;
